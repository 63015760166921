import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import axios from 'axios'
import VueElementLoading from "vue-element-loading";

Vue.config.productionTip = false

window.axios = require('axios')
// axios.defaults.baseURL = 'http://192.168.52.96:5300';
// Vue.prototype.baseURL = "http://192.168.52.96:5300"
// Vue.prototype.mediaURL = "http://192.168.52.96:5300/u/"
// Vue.prototype.mediaURLnew = "http://192.168.52.96:5300/file/get/"


axios.defaults.baseURL = 'https://api.onlineole.com/';
Vue.prototype.baseURL = "https://api.onlineole.com/"
Vue.prototype.mediaURL = "https://api.onlineole.com/"
Vue.prototype.mediaURLnew = "https://api.onlineole.com/file/get/"
Vue.component("VueElementLoading", VueElementLoading);

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
