<template>
  <div>
    <!-- <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    /> -->
    <!-- <v-app-bar color="#5d54a4"> -->
    <v-layout
      wrap
      justify-center
      class="hidden-sm-and-down gradientorange"
      style="height: 70px"
    >
      <v-flex xs12 class="fixItem">
        <!-- <v-img src="../../assets/Images/hd.jpg"> -->
        <v-layout wrap justify-center fill-height>
          <v-flex xs12 align-self-center>
            <v-card color="transparent" elevation="0">
              <v-layout wrap>
                <v-flex xs12 md7>
                  <!-- <router-link to="/" style="text-decoration: none"
                > -->
                  <v-layout wrap justify-center>
                    <v-flex xs9 align-self-center>
                      <v-img
                        style="cursor: pointer"
                        text
                        src="../assets/Images/logo.png"
                        width="230px"
                      ></v-img>
                    </v-flex>
                  </v-layout>
                  <!-- </router-link
              > -->
                </v-flex>
                <v-layout wrap justify-space-between>
                  <!-- <v-flex xs1 pt-1><a class="interSB" style="text-decoration:none; color:white" href="/#about">About</a></v-flex> -->

                  <v-flex
                    class="interSB"
                    style="text-align: justify"
                    text-center
                    align-self-center
                    v-for="(item, i) in category"
                    :key="i"
                  >
                    <router-link :to="item.route" :href="item.path" style="text-decoration: none">
                      <span
                        style="
                          cursor: pointer;
                          text-decoration: none;
                          color: white;
                        "
                        class="pack28 text1"
                        >{{ item.title }}</span
                      >
                    </router-link>
                  </v-flex>
                </v-layout>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
        <!-- </v-img> -->
      </v-flex>
    </v-layout>
    <v-layout class="hidden-md-and-up gradientorange">
      <v-flex xs12>
        <!-- <v-img src="../../assets/Images/hd.jpg"> -->
        <v-layout wrap>
          <v-flex xs11 align-self-start>
            <!-- <router-link to="/" style="text-decoration: none"
                > -->
            <v-layout wrap justify-start>
              <v-flex xs9>
                <v-img
                  text
                  src="../assets/Images/logo.png"
                  width="210px"
                ></v-img>
              </v-flex>
            </v-layout>
            <!-- </router-link
              > -->
          </v-flex>
          <v-flex xs1 pt-5>
            <v-app-bar-nav-icon @click="nav = true">
              <v-icon color="White">mdi-menu</v-icon>
            </v-app-bar-nav-icon>
          </v-flex>
        </v-layout>
        <!-- </v-img> -->
      </v-flex>
    </v-layout>
    <v-navigation-drawer
      right
      v-model="nav"
      app
      fixed
      temporary
      style="background-color: #7bc041"
    >
      <v-layout wrap justify-center>
        <v-flex
          style="font-family: intersemib; font-size: 17px; text-align: justify"
        >
          <v-list dense nav>
            <v-list-item
              v-for="item in category"
              :key="item.title"
              link
              :to="item.route"
            >
              <v-list-item-icon>
                <v-img height="20px" contain :src="item.src"></v-img>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title
                  ><span class="fnt1">{{ item.title }}</span></v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-flex>
      </v-layout>
    </v-navigation-drawer>
    <!-- </v-app-bar> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      nav: false,
      items: [
        { title: "Home", route: "/", type: "link" },
        {
          title: "About Us",
          route: "/AboutusPage",
          type: "link",
        },
        { title: "Our Team", route: "/LeadersPage", type: "link" },

        { title: "Campaign", route: "/campaign", type: "link" },
        { title: "Contact Us", route: "/ContactusPage"},
      ],
      category: [
        { title: "Home", route: "/", type: "link" },
        {
          title: "About Us",
          route: "/AboutusPage",
          type: "link",
          path: "#about",
        },
        { title: "Our Team", route: "/LeadersPage", type: "link" },
        { title: "Campaign", route: "/campaign", type: "link" },
        { title: "Contact Us", route: "/ContactusPage" },
        { title: "Register", route: "/Registration" },
      ],
    };
  },
};
</script>
<style >
.router-link {
  text-decoration: none;
}
.navbar {
  overflow: hidden;
  background-color: #333;
  position: fixed;
  top: 0;
  width: 100%;
}
.fixItem {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 1;
  /* overflow: hidden;
  position: relative;
  height: 100%; */
}
.fnt1 {
  font-size: 13px;
  font-weight: bold;
}

.gradientorange {
  /* background-image:inear-gradient(255.36deg, #7BC142 48.09%, #E95D49 127.63%) ; */
  background-image: linear-gradient(255.36deg, #7bc142 48.09%, #e95d49 127.63%);
}
</style>