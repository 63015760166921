import Vue from "vue";
import Router from "vue-router";
// import { Store } from "vuex";
Vue.use(Router);
let router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      props: true,
      component: () => import("./components/LandingPage"),
    },
    {
      path: "/campaign",
      name: "camp",
      props: true,
      component: () => import("./components/CampaignPage"),
    },
    {
      path: "/campaign1",
      name: "camp1",
      props: true,
      component: () => import("./components/CampaignOne"),
    },
    {
      path: "/ContactusPage",
      name: "ContactusPage",
      props: true,
      component: () => import("./components/ContactusPage"),
    },
    {
      path: "/LeadersPage",
      name: "LeadersPage",
      props: true,
      component: () => import("./components/LeadersPage"),
    },
    {
      path: "/AboutusPage",
      name: "AboutusPage",
      props: true,
      component: () => import("./components/AboutusPage"),
    },
    {
      path: "/Registration",
      name: "RegisterPage",
      props: true,
      component: () => import("./components/RegisterPage"),
    },
    {
      path: "/RegisterForm",
      name: "RegisterForm",
      props: true,
      component: () => import("./components/RegisterForm"),
    },
    {
      path: "/verifyzoom",
      name: "verifyzoom",
      props: true,
      component: () => import("./components/VerifyZoom"),
    },
    
    {
      path: "/PaymentPage",
      name: "PaymentPage",
      props: true,
      component: () => import("./components/PaymentPage"),
    },

    {
      path: "/privacypolicy",
      name: "PrivacyPolicy",
      props: true,
      component: () => import("./components/PrivacyPolicy"),
    },

    {
      path: "/termsandconditions",
      name: "TermsandConditions",
      props: true,
      component: () => import("./components/TermsandConditions"),
    },



  ],
  scrollBehavior() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  },

});



// router.beforeEach((to, from, next) => {
//   if (
//     to.matched.some((route) => route.meta.requiresAuth == true) &&
//     store.state.isLoggedIn == false
//   ) {
//     console.log(from);
//     next({ name: "login", params: { lastPage: from } });
//     return;
//   }


//   next();
// });

export default router;
